import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div>
        <h2>Welcome to Aha.Finance</h2>
        <p></p>
        <p>On Aha.Finance you can find the best online calculators relating to personal finance. On our site you can find all sorts of information like the best blogs, financial calculators and savings information. </p>

        <p>Click on Calculators to find the right calculator for your needs. Happy planning!</p>
      </div>
    );
  }
}
 
export default Home;