import React, { Component } from "react";
import {Route, Link, HashRouter} from "react-router-dom";
import Home from "./Home";
import NetWorthCalculator from "./Calculators";
import Contact from "./Contact";

class Main extends Component {
    render() {
      return (
        <HashRouter>
          <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <span class="navbar-brand mb-0 h1">Aha.Finance</span>
            <ul className="navbar-nav mr-auto">
                <li><Link to={'/'} className="nav-link"> Home </Link></li>
                <li><Link to={'/Calculator'} className="nav-link">Calculators</Link></li>
                <li><Link to={'/contact'} className="nav-link">Contact</Link></li>
            </ul>
            </nav>
            <div className="content">
                <Route exact path="/" component={Home}/>
                <Route path="/Calculator" component={NetWorthCalculator}/>
                <Route path="/contact" component={Contact}/>
            </div>
          </div>
        </HashRouter>
      );
    }
  }

export default Main;
