import React, { Component } from "react";
 
class Contact extends Component {
    render() {
        return (
          <div>
            <h2>Got questions?</h2>
            <p></p>
            <p>This site was set up by Sekar Velu as a project to learn about ReactJS and is just for fun. Email me at s p v e l u @ g m a i l . c o m (no spaces IRL :)) if you have any questions.</p>
          </div>
        );
      }
    }
 
export default Contact;