import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {Dropdown} from "react-bootstrap";
import Table from "react-bootstrap/Table";


class NetWorthCalculator extends Component {
  
  constructor(props){
    super(props);
    this.state = {btnTitle: "Select Asset", formValue: "", assets: [{type:"",amount:""}]};
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <div>
        <h2>Net Worth Calculator</h2>
        <p></p>
        <h3> Assets </h3>
        <InputGroup className="mb-3">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" title={this.state.btnTitle}> 
              {this.state.btnTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={0} onSelect={this.handleSelect}>House</Dropdown.Item>
              <Dropdown.Item eventKey={1} onSelect={this.handleSelect}>Savings</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <FormControl
            label="Recipient's username"
            aria-describedby="basic-addon2"
            onChange={this.handleFormUpdate}
          />        

        <Button type="submit" onClick={this.handleSubmit}>Add</Button>

        </InputGroup>

        <Table striped bordered hover variant="dark" size="sm" data={this.state.assets}>
          <thead>
            <tr>
              <th>Type</th>
              <th>$ Amount </th>
             </tr>
          </thead>
          <tbody>
          </tbody>
        </Table>

        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th>Total:  </th>
              <th>$ Amount:   </th>
             </tr>
          </thead>
        </Table>

      </div>
    );
  }

  fireAlert(text){
    console.log(text);
  }

  handleSelect(event) {
    var evtKey = event;
    switch(evtKey[0]) {
      case "0":
        this.setState({btnTitle: "House"});
        break;
      case "1":
        this.setState({btnTitle: "Savings"});
        break;
      default:
        this.setState({btnTitle: "Other"});
    }
  }

  handleFormUpdate(event){
    this.setState({formValue: event.target.value});
  }

  handleSubmit(event) {
    console.log("inside handleSubmit");
    this.setState({assets: {type: this.state.btnTitle,amount: this.state.formValue}});
    event.preventDefault();
  }

}

export default NetWorthCalculator;